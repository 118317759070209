<template>
  <TopNav></TopNav>
  <h1>Yaş Hesaplama</h1>
  <fieldset>
    <legend>Yaş Hesaplama Aracı</legend>
    <label for="birthdate">Doğum tarihi:</label> <input type="date" v-model="birth" id="birthdate">
    <br><br>
    Yaş: <span class="blue">{{ age }}</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'
import moment from 'moment'

export default {
  name: "Yas",
  data() {
    return {
      birth: "",
    }
  },
  components: {
    TopNav
  },
  computed: {
    age: function () {
      const formatter = new Intl.NumberFormat('tr-TR', {
        maximumFractionDigits: 0
      });
      let a = moment(this.birth);
      let b = moment(new Date());
      let age = b.diff(a, 'years', true);
      let ageNumber = Number(age);
      return formatter.format(ageNumber);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 125px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
